<template>
  <div class="StaffTwitterCallback" />
</template>

<script>
/**
 * Used for authenticating Twitter profiles from the {@see module:Staff/AuthenticateTwitter}
 * @module StaffTwitterCallback
 */
export default {
  name: 'StaffTwitterCallback',

  computed: {
    token () {
      return this.$route.query.oauth_verifier
    }
  },

  mounted () {
    this.sendTokenToApi()
  },

  methods: {
    sendTokenToApi () {
      if (!this.token) return

      const loading = this.$loading({
        lock: true,
        text: 'Saving token',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      const payload = {
        oauth_verifier: this.token
      }
      this.$api.post('staff/oauth/twitter/token', payload)
        .then((response) => {
          this.$router.push({
            query: {
              token_received: true
            }
          })
        })
        .catch((error) => {
          this.$displayRequestError(error)
        })
        .finally(() => {
          loading.close()
        })
    }
  }
}
</script>
